
<transition name="modal" v-if="showModal">
  <div class="modal-mask" role="dialog">
    <div class="modal-dialog" :class="modalClass" role="document">
      <div class="modal-content">

        <div class="modal-header">
          <h4 class="modal-title">Crear cuenta</h4>
          <button type="button" class="btn close" @click="hide()">X</button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <label for="first_name">*Nombre(s)</label>
              <input autocomplete="on" type="first_name" v-model="contact.first_name" placeholder="*Nombre(s)" class="navb-box" @keyup.enter="login()">
              <label class="error" v-if="errors.first_name">{{errors.first_name}}</label>
            </div>

            <div class="col-md-6">
              <label for="last_name">*Apellidos</label>
              <input autocomplete="on" type="last_name" v-model="contact.last_name" placeholder="*Apellidos" class="navb-box" @keyup.enter="login()">
              <label class="error" v-if="errors.last_name">{{errors.last_name}}</label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label for="email">*Correo electrónico</label>
              <input autocomplete="off" type="email" v-model="contact.email" placeholder="*Correo electrónico" class="navb-box" @keyup.enter="login()">
              <label class="error" v-if="errors.email">{{errors.email}}</label>
            </div>

            <div class="col-md-6">
              <label for="password">*Contraseña</label>
              <input autocomplete="new-password" type="password" v-model="contact.password" placeholder="*Contraseña" class="navb-box" @keyup.enter="login()">
              <label class="error" v-if="errors.password">{{errors.password}}</label>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <button v-if="!status.create" type="button" class="btn btn-success" @click="create()">Crear</button>
            <div class="spinner-grow" role="status" v-else>
              <span class="sr-only">Loading...</span>
            </div>
            <button type="button" class="btn btn-danger" @click="hide()">Cancelar</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</transition>
