<template>
  <transition name="modal" v-if="showModal">
    <div class="modal-mask" role="dialog">
      <div class="modal-dialog" :class="modalClass" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h4 class="modal-title">Crear cuenta</h4>
            <button type="button" class="btn close" @click="hide()">X</button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <label for="first_name">*Nombre(s)</label>
                <input autocomplete="on" type="first_name" v-model="contact.first_name" placeholder="*Nombre(s)" class="navb-box" @keyup.enter="login()">
                <label class="error" v-if="errors.first_name">{{errors.first_name}}</label>
              </div>

              <div class="col-md-6">
                <label for="last_name">*Apellidos</label>
                <input autocomplete="on" type="last_name" v-model="contact.last_name" placeholder="*Apellidos" class="navb-box" @keyup.enter="login()">
                <label class="error" v-if="errors.last_name">{{errors.last_name}}</label>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label for="email">*Correo electrónico</label>
                <input autocomplete="off" type="email" v-model="contact.email" placeholder="*Correo electrónico" class="navb-box" @keyup.enter="login()">
                <label class="error" v-if="errors.email">{{errors.email}}</label>
              </div>

              <div class="col-md-6">
                <label for="password">*Contraseña</label>
                <input autocomplete="new-password" type="password" v-model="contact.password" placeholder="*Contraseña" class="navb-box" @keyup.enter="login()">
                <label class="error" v-if="errors.password">{{errors.password}}</label>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button v-if="!status.create" type="button" class="btn btn-success" @click="create()">Crear</button>
              <div class="spinner-grow" role="status" v-else>
                <span class="sr-only">Loading...</span>
              </div>
              <button type="button" class="btn btn-danger" @click="hide()">Cancelar</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script nonce>
import axios from 'axios';

  export default {
    props: {
      modalClass: {
        type: String
      }
    },
    data() {
      return {
        showModal: false,
        contact: {
          first_name: null,
          last_name: null,
          email: null,
          password: null
        },
        errors: {},
        status: {
          create: false
        }
      }
    },
    watch: {
      "contact.first_name": {
        handler: function (newValue, oldValue) {
          if(this.showModal){
            this.validateField('first_name');
          }
        },
        immediate: true
      },
      "contact.last_name": {
        handler: function (newValue, oldValue) {
          if(this.showModal){
            this.validateField('last_name');
          }
        },
        immediate: true
      },
      "contact.email": {
        handler: function (newValue, oldValue) {
          if(this.showModal){
            this.validateFormat('email');
          }
        },
        immediate: true
      },
      "contact.password": {
        handler: function (newValue, oldValue) {
          if(this.showModal){
            this.validateFormat('password');
          }
        },
        immediate: true
      }
    },

    methods: {
      open() {
        this.showModal = true;
      },
      hide() {
        this.showModal = false;
      },
      validateField(field) {
        // Delete property of errors if exists
        delete this.errors[field];
        // Restore reactivity on object
        this.errors = Object.assign({}, this.errors);
        if(!this.contact[field]){
          // For all inputs
          this.errors[field] = 'Este campo es requerido';
          this.errors = JSON.parse(JSON.stringify(this.errors));
        }
      },
      validateFormat(field) {
        var regex = "";

        if (field == 'email') {
          regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        } else if (field == 'password'){
          regex = /^.{6,}$/;
        }
        // Restore reactivity on object
        delete this.errors[field];

        if(!this.contact[field]){
          this.errors[field] = 'Este campo es requerido';
          this.errors = JSON.parse(JSON.stringify(this.errors));
        }else{
          if( regex && !regex.test(String(this.contact[field]).toLowerCase()) ){   
            this.errors[field] = field == 'email' ? 'Correo invalido' : "La contraseña debe ser mayor a 5 dígitos";
            this.errors = JSON.parse(JSON.stringify(this.errors));
          }
        }
      },
      create() {
        this.validateField('first_name');
        this.validateField('last_name');
        this.validateFormat('email');
        this.validateFormat('password');

        // Check that doesn't exists errors with email field
        if(Object.entries(this.errors).length === 0 && this.errors.constructor === Object){
          this.status.create = true;
          axios.post('/api/public/v1/contacts/register', {
            data: this.contact
          }, {
            headers: {
              "x-api-key": "NPP3dB1ch8CiEmRGprtB2Vpr7xbZdA7iUnw7gX7ORjXdX0vzQ"
            }
          }).then((response)=>{
            this.$awn.success(`Gracias por registrarte`);
            if(response.data.message)this.$awn.warning(response.data.message);
            this.hide();
          }).catch((error) => {
            this.$awn.warning('Error del servidor');
          }).finally(() =>{
            this.status.create = false;
          });
        }
      }
    }
  }
</script>

<style lang="scss">  
.modal-dialog {
  max-width: 96vw;
  width: 96vw;
  position: absolute !important;
  position-area: center;

  @media (min-width: 1200px) {
    max-width: 70vw !important;
  }
}
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1040;
  background-color: rgba(0, 0, 0, .5);
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
}

.error {
  color: red;
}
</style>
